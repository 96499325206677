import React, { useEffect } from "react";
import CustomModal from "./CustomModal";
import Logo from '../../Assets/Images/krutak.webp'
import Thankyou from '../../Assets/Images/thankyou.webp'
const ThankYouModal = ({ open, setOpen }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])
    return (

        <div>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                {/* <div className="modal-content contact-container w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="text-center pt-3">
                            <img className="thnk-modal-logo" src="" alt=""></img>
                        </div>
                        <hr />
                        <div className="container ">
                            <div className="row d-flex align-items-center justify-content-center  my-4">
                                <div className="container px-5">
                                    <div className="row justify-content-center  ">
                                        <div className="col-lg-12 justify-content-center">
                                            <div className="text-center">
                                                <img className="success-img" src="" alt=""></img>
                                            </div>

                                            <h1 className="text-center mb-3 font-bold">Thank You!</h1>
                                            <p className="mb-0 text-center">Your details has been successfully submitted. Thank You!</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="btn  mt-5  w-100 btn-primary contact-btn"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="modal-content">
                    <div className="tnk-head">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="th-cnt">
                        <img src={Thankyou} alt="Thanks" />
                        <h1 className="font-bold">Thank You</h1>
                        <p>Your details have been successfully submitted.</p>
                        <button
                            type="button"
                            className="tnk-btn btn btn-primary"
                            onClick={() => {
                                setOpen(false);
                                window.location.reload()
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ThankYouModal;
