import React, { useState, useEffect } from "react";
import axios from "axios";
import ThankYouModal from "./Modal/ThankyouModal";
import { useRef } from "react";
import Logoimg from "../Assets/Images/logo.webp";
import { NavLink, useLocation } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Computer from "../Assets/Images/computer.webp";
import k1 from "../Assets/Images/kk1.webp";
import k2 from "../Assets/Images/kk2.webp";
import k3 from "../Assets/Images/kk3.webp";
import k4 from "../Assets/Images/kk4.webp";
import k5 from "../Assets/Images/kk5.webp";
import k6 from "../Assets/Images/kk6.webp";
import k7 from "../Assets/Images/kk7.webp";
import k8 from "../Assets/Images/kk8.webp";
import Guru from "../Assets/Images/guru.webp";
import Pankaj from "../Assets/Images/pankaj.webp";
import Stuart from "../Assets/Images/stuart.webp";
import Niveditha from "../Assets/Images/niveditha.webp";
import Nanda from "../Assets/Images/nanda.webp";
import Pugalendhi from "../Assets/Images/pugalenthi.webp";
import Manikandan from "../Assets/Images/manikandan.webp";
import Phaniraj from "../Assets/Images/phaniraj.webp";
import Subhomoy from "../Assets/Images/subhomoy.webp";
import K1 from "../Assets/Images/kalyanaraman.webp";
import P1 from "../Assets/Images/om1.webp";
import P2 from "../Assets/Images/om2.webp";
import G1 from "../Assets/Images/g1.webp";
import G2 from "../Assets/Images/g2.webp";
import G3 from "../Assets/Images/g3.webp";
import U1 from "../Assets/Images/krutak.webp";
import U3 from "../Assets/Images/simplilearn.webp";
import U5 from "../Assets/Images/excelr.webp";
import U2 from "../Assets/Images/guvi.webp";
import U4 from "../Assets/Images/intellipoat.webp";
import Star from "../Assets/Images/star.webp";
import User from "../Assets/Images/user.webp";
import Syllabus from "../Assets/file/syllabus.pdf";
import Chart1 from "../Assets/Images/chart-1.webp";
import Chart2 from "../Assets/Images/chart-2.webp";
import logo from "../Assets/Images/logo.webp";
const HomeContent = () => {
  const inputRef = useRef(null);
  const [create, setCreate] = useState({});
  const [closeNav, setCloseNav] = useState(false);
  const [validation, setValidation] = useState({});
  const [checked, setChecked] = useState(false);
  console.log(checked)
  const [error, setError] = useState("");
  const [thankYouModal, setThankYouModal] = useState(false);
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const downloadpdf = (path, filename) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    // Append to the DOM
    document.body.appendChild(anchor);
    // Trigger `click` event
    anchor.click();
    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  // useEffect(() => {
  //     window.scrollTo({ top: 0 });
  // }, [])
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    const containerId = search.substring(4); // Remove the leading slash
    const element = document.getElementById(containerId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
    if (containerId === "info" && inputRef.current) {
      inputRef.current.focus();
    }
  }, [location]);

  const setData = (e, key) => {
    setCreate({ ...create, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  const handleCheckChange = (e) => {
    setError("");
    setChecked(e.target.checked);
  };
  const handleDownload = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setDownload(true);
  };

  const containsnumber = (str) => {
    return /\d/.test(str);

  }
  function isEmail(email) {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      .test(email);
  }
  console.log(isEmail(create.Email))
  const CheckValidation = () => {
    let flag = true;
    let validdateData = {};
    if (!create.Name) {
      validdateData.Name = "Name is Required";
      flag = false;
    }
    if (create.Name && create.Name.length < 3) {
      validdateData.Name = "Name should contain 3 characters";
      flag = false;
    }
    if (create.Name && containsnumber(create.Name)) {
      validdateData.Name = "Name is invalid";
      flag = false;
    }
    if (!create.Phone) {
      flag = false;
      validdateData.Phone = "Phone Number is required";
    }
    if (create.Phone && create.Phone.length !== 10) {
      flag = false;
      validdateData.Phone = "Phone Number must be 10 digits";
    }
    if (!create.Email) {
      flag = false;
      validdateData.Email = "Email is Required";
    }
    if (create.Email && isEmail(create.Email)) {
      flag = false;
      validdateData.Email = "Enter Valid email id";
    }
    if (checked === false) {
      flag = false;
      setError("please accept terms and conditions");
    } else {
      setError("");
    }
    setValidation({ ...validation, ...validdateData });
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    setLoading(true);

    let downloaded = "No";
    if (download) {
      downloaded = "Yes";
    }

    if (CheckValidation()) {
      try {
        let inputValue = {
          Name: create.Name,
          Phone: create.Phone,
          Email: create.Email,
          Date: currentDate.toDateString(),
          Syllabus: downloaded,
        };
        let form_data = new FormData();
        for (let key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        // createOrder(create.Name)
        await axios
          .post(
            "https://script.google.com/macros/s/AKfycbzvu7xkUmRK8MjsBvIUdA5OhYCzYIplSH8sGVh8GciRevwKDBnrh6TevlvMIcAdkxA_/exec",
            form_data
          )
          .then(() => {
            setChecked(false);
            createOrder(create.Name)
            if (download) {
              downloadpdf(Syllabus, "Krutak Research Syllabus.pdf");
            }
            setDownload(false);
            setCreate((prevState) => ({
              ...prevState,
              Name: "",
              Phone: "",
              Email: "",
            }));
            setChecked(false);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error writing to Google Sheets:", error);
      }
    } else {
      setLoading(false);
    }
  };

  const paymentTrack = async (data, paymentId, signature) => {
    const currentDate = new Date();
    try {
      let inputValue = {
        Name: create.Name,
        Phone: create.Phone,
        Email: create.Email,
        Signature: signature,
        PaymentId: paymentId,
        PaymentResponse: data.data,
        Date: currentDate.toDateString()
      };
      let form_data = new FormData();
      for (let key in inputValue) {
        form_data.append(key, inputValue[key]);
      }
      // createOrder(create.Name)
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbwHDHbrEG0kV1x2Fc28DJYlELR220eS9j6HT0kGQAX1xymrbQjJBCS6my7zaQ5Hvtg/exec",
          form_data
        )
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setThankYouModal(true)
        });
    } catch (error) {
      setLoading(false);
      console.error("Error writing to Google Sheets:", error);
    }
  };

  const createOrder = async (name) => {

    try {
      let inputValue = {
        amount: 18000 *100 ,
        currency: 'INR',
        name: name,
      };
      let form_data = new FormData();
      for (let key in inputValue) {
        form_data.append(key, inputValue[key]);
      }
      let data = await axios
        .post(
          "https://onlinedatasciencecourse.krutakresearchanalytics.com/api/api.php",
          form_data
        )
      // setOrderId(data.data.order_id);
      handlePayment(data.data.order_id)
    } catch (error) {
      console.error("Error writing to Google Sheets:", error);
    }
  }
  const validatePayment = async (signature, paymentId) => {
    try {
      let inputValue = {
        razorpay_payment_id: paymentId,
        razorpay_signature: signature
      };
      console.log("inputValue", inputValue)
      let form_data = new FormData();
      for (let key in inputValue) {
        form_data.append(key, inputValue[key]);
      }
      let data = await axios
        .post(
          "https://onlinedatasciencecourse.krutakresearchanalytics.com/api/api.php",
          form_data
        )
      paymentTrack(data, paymentId, signature)
      console.log("Data", data)
    } catch (error) {
      console.error("Error writing to Google Sheets:", error);
    }
  }
  const handlePayment = async (orderId) => {
    const options = {
      // key: 'rzp_test_4EjU0TZdE3NhQs',
      key: 'rzp_live_2YhmtGbPR9roqu',
      amount: Number(18000 * 100),
      currency: 'INR',
      name: create.Name,
      order_id: orderId,
      handler: function (response) {
        if (response.razorpay_signature) {
          validatePayment(response.razorpay_signature, response.razorpay_payment_id)
        }
      },
      prefill: {
        name: create.Name,
        email: create.Email,
        contact: create.Phone,
      },
      theme: {
        color: '#043a59',
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const Logo = [
    {
      image: k1,
      header: "Mode",
      content: "Online Interactive Class",
    },
    {
      image: k2,
      header: "Duration",
      content: "2 Months course + 1 Month for Project Support",
    },
    {
      image: k3,
      header: "Class Hours",
      content: "You can choose either weekday classes or only weekend classes",
    },
    {
      image: k4,
      header: "Total Hours",
      content: "50 hours + 10 hours for doubt-clearing",
    },
    {
      image: k5,
      header: "Refund Policy",
      content: "2 Week Money Back Guarantee",
    },
    {
      image: k6,
      header: "Certification",
      content: "Industry recognized certification",
    },
    {
      image: k7,
      header: "Learning",
      content: "100% Practical, Industry Use Case Oriented",
    },
    {
      image: k8,
      header: "Admission",
      content:
        "Only limited seats available to ensure personalized attention for all",
    },
  ];
  const Review = [
    {
      image: Guru,
      name: "Guru Radhakrishnan",
      role: "Co-Founder & CEO at Focus Research Labs Inc",
      msg: "“Kalyan has great enthusiasm for new ideas, very driven individual. He open and friendly to work with. His Energy and Enthusiasm is great. Man of ideas and eager to try out new things.”",
    },
    {
      image: Pankaj,
      name: "Pankaj Bijlwan",
      role: "Senior Manager - Finance & Accounts, Tata Power",
      msg: "“Recently I got an opportunity to get one training from Kalyan . I would like to say that it was a very good training session. Kalyan was well equipped with his data and we were getting all the clarity then and there. I would like to strongly recommend his training skills.”",
    },
    {
      image: Stuart,
      name: "Stuart Clarke",
      role: "Chief Consultant at SAP",
      msg: "“Kalyanaraman is an experienced and knowledgeable data scientist. He clearly has had many years working with and analysing data, and he knows his way round the techniques and algorithms we use. He is also a very nice guy!”",
    },
    {
      image: Niveditha,
      name: "Niveditha Prakash",
      role: "Data Scientist at MiQ",
      msg: "“Kalyan has been an exemplary and an enthusiastic mentor during my six months internship period . He has a strong willingness to share his knowledge and expertise in analytics field. He has been motivating and guiding me with my project work right from the beginning. Dedicated to his work, he has an eagerness to learn new things and share his ideas and thoughts. It was a pleasure working with him which created an interest for me in the field of Data Science.”",
    },
    {
      image: Nanda,
      name: "Nanda Chintaluri",
      role: "Global Operations Leader, Vice - President - Research & Analytics, RRD - Global Outsourcing Solutions, APAC",
      msg: "“Kalyan has been instrumental in training and mentoring our Data Science team! He was very dedicated and spent time in personally coaching the team members. Thank you Kalyan for your contribution and passion. Wish you all the best!”",
    },
    {
      image: Pugalendhi,
      name: "PUKALENTHI KATHIRVEL",
      role: "Business Analytics And Mining, VuNet Systems",
      msg: "“It was great opportunity to be part of Great Lakes Business Analytics Course with Kalyan. Being (in) hard core Data Scientist Role, Kalyan has the capability to analyze the data effectively. During our course, he was qucik enough to provide the solution on any complicated scenarios. His input was very vital during our Capstone Project submission.”",
    },
    {
      image: Manikandan,
      name: "Manikandan Mohan",
      role: "Service Delivery Leader, RRD - Global Outsourcing Solutions, APAC",
      msg: "“Thank you for being an amazing mentor, Kalyan. You’re great in many way! You inspire us to turn our data science dreams into reality. It was a wonderful journey with you for the past 2 years. Your supervision and care never failed to amaze us. Appreciate your time, sharing your experience and being our side and motivating us. Thank you for being an instrumental for our data science journey and being our best mentor for Trailblazer crew. Wishing you all the very best for your future endeavours!.”",
    },
    {
      image: Phaniraj,
      name: "Phaniraj Tarakaram Nori",
      role: "Associate, Cognizant",
      msg: "“I have had the privilege to be mentored by Kalyan in the initial days of my career. Not only a good teacher, Kalyan has always been synonymous to hard-work. He exhibited strong appetite for learning at every stage of his tenure at Cognizant. Having rich experience in Banking activities, Kalyan has done some fascinating work with analytics in that sector, which I had witnessed and learnt much from. Given a chance to work with him, I would be happy to be managed by him again.”",
    },
    {
      image: Subhomoy,
      name: "Subhomoy Chattopadhyay",
      role: "ML Engineer, Accenture",
      msg: "“I am one of your students you taught ML-1 in last year February in pes university campus. I am really glad to inform you that I cracked 3 interviews ( wipro, cognizant and PwC) and decided to join PwC as a data scientist in senior associate position in July. I am really grateful to you for your wonderful teaching and I would like to mention that I can’t forget your statement ‘one should remember the concept really well and one should know what to use when’. Thank you so much sir!",
    },
    {
      image: User,
      name: "Sanjai Srinivasan",
      role: "Digital Banking Leader at Deloitte",
      msg: "“Kalyan is having an excellent domain knowledge with strong analytical skills. He comes with rich banking , IT and analytics experience. I greatly enjoyed working with him. Best of luck for his future endeavours.”",
    },
  ];
  const Project = [
    {
      img: G1,
      content: "Intensive Group Project Submission",
    },
    {
      img: G2,
      content: "Supported by  Industry  Experts",
    },
    {
      img: G3,
      content: "Gain practical experience in real-world scenarios",
    },
  ];
  const Compare = [
    {
      image: U2,
      name: "GUVI",
      amount: "80,000",
    },
    {
      image: U3,
      name: "simplilearn",
      amount: "54,000",
    },
    {
      image: U4,
      name: "Intellipaat",
      amount: "1,00,035",
    },
    {
      image: U5,
      name: "Excelr",
      amount: "59,000",
    },
  ];
  return (
    <div className="horizontal-scroll-issue">
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
      <div className="bg-light-nav-top">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <div className="container">
            <NavLink className="navbar-brand d-flex align-items-end" to="/">
              <img className="logo-img" src={Logoimg} alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${closeNav === true ? "d-none" : ""
                }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/about"
                  >
                    About Krutak
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/team"
                  >
                    Leadership Team
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/service"
                  >
                    Services
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/product">Products</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/position"
                  >
                    Careers
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/course">Webinar</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-primary nav-btn"
                    onClick={() => {
                      handleClick();
                      setCloseNav(true);
                      setTimeout(() => {
                        setCloseNav(false);
                      }, [1000]);
                    }}
                  >
                    Get in Touch
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* <div className="container topcontent">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div
              className="section-1"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <h2 className="tx-color">
                Advanced Data Science ​Course Online (Live)
              </h2>
              <h1>Learn with Use Cases from Experts</h1>
              <div
                className="box"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              ></div>
              <p className="para-color">
                Begin your transformation journey with our Data Science Course
                (Live) Online and learn with real-world use cases from our
                (Industry) experts for a successful career transition at the
                least cost. It is an opportunity to unlock the power of data
                science within 2 months.
              </p>
              <button
                type="button"
                onClick={handleClick}
                className="btn btn-primary nav-btn"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                Join Now
              </button>
            </div>
          </div>
          <div
            className="col-lg-6 col-12 mdimg d-flex justify-content-lg-end"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <img src={Computer} alt="computer" className="computer" />
          </div>
        </div>
      </div> */}
      <div className="topcontent" id="#info">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div
                className="section-1"
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <h1>Advanced Data Science ​Course Online (Live) </h1>
                {/* <h1 className="home-banner-heading">Advanced Data Science ​Course Online (Live) </h1> */}
                <h2 className="tx-color">
                  For Technical and
                  Non-Technical Professionals
                </h2>


                <div
                  className="box"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                ></div>
                <p className="para-color">
                  <ul className="mt-5 list">
                    <li className="mb-4">
                      To become Master of Data Science and Machine Learning, this
                      course is crafted keeping in mind the present and future
                      industry needs.
                    </li>
                    <li>
                      All sessions are 100% hands-on lab oriented sessions for
                      practical learning.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="form">
                <div className="headerx">
                  <img src={Star} alt="" className="sr-1" />
                  <h4
                    className="tx-color"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    data-aos-duration="1500"
                  >
                    Limited seats available.<br/> Register now & reserve your spot
                  </h4>
                  <img src={Star} alt="" className="sr-2" />
                </div>
                {/* {download &&
                                    <div className='dnld' style={{ color: "green" }} p>
                                        ***Please fill the details to download syllabus. Thank you!***
                                    </div>
                                } */}

                <div className="">
                  <div
                    className="mb-2 form-element"
                  // data-aos="fade-left"
                  // data-aos-delay="100"
                  // data-aos-duration="1500"
                  >
                    <div>
                      <label
                        htmlFor="name"
                        className="tx-color font-semibold mb-2"
                      >
                        Name:
                      </label>
                      <input
                        ref={inputRef}
                        type="text"
                        className="form-control"
                        id="name"
                        name="Name"
                        value={create.Name}
                        onChange={(e) => {
                          setData(e.target.value, "Name");
                        }}
                      />
                    </div>
                    <div>
                      <p className="error-text">{validation.Name}</p>
                    </div>
                  </div>
                  <div
                    className="mb-2 form-element"
                  // data-aos="fade-right"
                  // data-aos-delay="100"
                  // data-aos-duration="1500"
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="tx-color font-semibold mb-2"
                      >
                        Email:
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="Email"
                        value={create.Email}
                        onChange={(e) => {
                          setData(e.target.value, "Email");
                        }}
                      />
                    </div>
                    <div>
                      <p className="error-text">{validation.Email}</p>
                    </div>
                  </div>
                  <div
                    className="mb-2 form-element"
                  // data-aos="fade-left"
                  // data-aos-delay="100"
                  // data-aos-duration="1500"
                  >
                    <div>
                      <label
                        htmlFor="phone"
                        className="tx-color font-semibold mb-2"
                      >
                        Phone:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="phone"
                        value={create.Phone}
                        name="Phone"
                        onChange={(e) => {
                          setData(e.target.value, "Phone");
                        }}
                      />
                    </div>
                    <div>
                      <p className="error-text">{validation.Phone}</p>
                    </div>
                  </div>
                  <div
                    className="form-check"
                  // data-aos="fade-right"
                  // data-aos-delay="100"
                  // data-aos-duration="1500"
                  >
                    <div>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check"
                        onChange={(e) => handleCheckChange(e)}
                        checked={checked}
                      />
                      <label
                        className="form-check-label"
                        style={{ color: "#646464" }}
                        htmlFor="check"
                      >
                        By submitting the form, you agree to our{" "}
                        <a href="#/Termsandconditions"> Terms and Conditions </a> and
                        our <a href="#/Privacypolicy"> Privacy Policy</a>.
                      </label>
                    </div>
                    <div>
                      <p className="error-text" style={{ marginLeft: 0 }}>
                        {/* {validation.Check} */}
                        {error && <div style={{ color: "red" }}>{error}</div>}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-lg-end justify-content-center form-btn">
                    <button
                      className="btn btn-primary nav-btn"
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                    >
                      {loading ? (
                        <i className="fa fa-circle-notch fa-solid"></i>
                      ) : (
                        "Register Now"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logo">
        <div className="container">
          <div className="row">
            {Logo.map((item, index) => {
              return (
                <div
                  className="col-lg-3 col-md-6 col-6 "
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  key={index}
                >
                  <div className="p-2 card1">
                    <img src={item.image} alt="logo" className="pb-3   " />
                    <h5 className="font-bold pb-2">{item.header}</h5>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h4 className="font-bold text-center tx-color clicsl">
          What Our Clients Say?
        </h4>
        <div className="csl">
          <OwlCarousel
            className="owl-theme"
            loop
            autoplay={false}
            items="2"
            dots={true}
            smartSpeed={2000}
            nav={false}
            margin={20}
            // center={true}
            autoplayTimeout={5000}
            responsive={{
              360: {
                items: "1",
              },
              414: {
                items: "1",
              },
              670: {
                items: "1",
              },
              992: {
                items: "2",
              },
              1200: {
                items: "2",
              },
            }}
          >
            {Review.map((item, index) => {
              return (
                <div
                  className="csl-card"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                  key={index}
                >
                  <div>
                    <div className="csl-cnt-1">
                      <div>
                        <img
                          src={item.image}
                          alt="profile"
                          className="csl-image"
                        />
                      </div>
                      <div className="csl-tx">
                        <h3 className="m-0 font-bold">{item.name}</h3>
                        <p className="mt-3 mb-0 w-75 font-bold">{item.role}</p>
                      </div>
                    </div>
                    <div>
                      <i className="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="mt-5 font-bold" style={{ color: "#363A3D" }}>
                    {item.msg}
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
      <div className="fdr">
        <div className="container">
          <div className="row justify-content-lg-center">
            <div className="col-lg-3 col-md-5">
              <div
                className="profile"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img src={K1} alt="profile" />
                <h6 className="font-bold">Kalyanaraman Ramani</h6>
                <p>
                  Founder and CEO of<br></br> Krutak Research Analytics
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div
                className="pr-content"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <h2 className="font-bold mb-3 tx-color">
                  Get Trained from Industry Expert
                </h2>
                <p className="tx-color fs-5">
                  <span className="font-bold tx-color ">Bio : </span>Experienced
                  Corporate Trainer renowned for delivering impactful training
                  sessions across industry leaders such as Tata Group, Sopra
                  Steria, Welspun, The Times, Hindustan Unilever Limited (HUL),
                  RR Donnelley, AstraZeneca, Societe Generale, Tiger Analytics,
                  IDFC Bank, MassMutual, MiQ, Coforge, and Larsen & Toubro
                  Infotech (LTI). Trusted by top-tier organizations, I
                  specialize in cultivating a dynamic learning environment that
                  fosters professional development, enhances employee skills,
                  and drives tangible results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="program">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <h4 className="font-bold tx-color">Program Overview</h4>
              <p className="pr-p" style={{ color: "#646464" }}>
                This data science and machine learning course is perfectly
                suitable for professionals from non-technical and technical
                backgrounds.
              </p>
              <p className="pr-p" style={{ color: "#646464" }}>
                After the successful completion of this course, here are the
                opportunities for you
              </p>
              <div className="two-btn">
                <button
                  className="btn btn-primary nav-btn"
                  onClick={handleClick}
                >
                  Register Now
                </button>
                {/* <a target='_blank' download="Syllabus" href={Syllabus} rel="noreferrer"> */}
                <button
                  className="btn btn-primary nav-btn2 "
                  onClick={handleDownload}
                >
                  Download Syllabus
                </button>
                {/* </a> */}
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div
                className="pro-section"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <h4 className="font-semibold">
                  <span>{""}</span>Non-technical background
                </h4>
                <p>Data Science Consultant</p>

                <h4 className="font-semibold mt-5">
                  <span>{""}</span>Technical background
                </h4>
                <p>Data Analyst | Data Scientist</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="course">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h3
                className="font-bold text-center tx-color"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                Course Outline
              </h3>
              <div
                className="accordion  my-5"
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
                id="accordionexample"
              >
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingone">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseone"
                      aria-expanded="false"
                      aria-controls="collapseone"
                    >
                      Fundamental of Python
                    </button>
                  </h2>
                  <div
                    id="collapseone"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingone"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>Introduction to Python</li>
                        <li>String/Print/Indentation</li>
                        <li>Conditional Operators</li>
                        <li>Loop and Control statements</li>
                        <li>
                          Data Structures - Lists and Tuples, Sets, Dictionaries
                        </li>
                        <li>Data Collection – CSV / Excel files</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingtwo">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsetwo"
                      aria-expanded="false"
                      aria-controls="collapsetwo"
                    >
                      Data Transformation
                    </button>
                  </h2>
                  <div
                    id="collapsetwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingtwo"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>
                          Data preprocessing/handling missing values/Formatting
                        </li>
                        <li>Data Rescaling</li>
                        <li>Standardize the data.</li>
                        <li>Handling categorical data</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnothree">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenothree"
                      aria-expanded="false"
                      aria-controls="collapsenothree"
                    >
                      Pandas
                    </button>
                  </h2>
                  <div
                    id="collapsenothree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnothree"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>
                          Importing multiple Datasets using Pandas DataFrame
                        </li>
                        <li>Filter / Sort / Group-by</li>
                        <li>Join / Combine</li>
                        <li>Indexing and Selecting</li>
                        <li>Grouping and Summarizing</li>
                        <li>Aggregation</li>
                        <li>Concatenation</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnofour">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenofour"
                      aria-expanded="false"
                      aria-controls="collapsenofour"
                    >
                      Numpy
                    </button>
                  </h2>
                  <div
                    id="collapsenofour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnofour"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>Indexing and Slicing</li>
                        <li>Array manipulation</li>
                        <li>Sort, Search, Count</li>
                        <li>1D / 2D / 3D – data</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnofive">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenofive"
                      aria-expanded="false"
                      aria-controls="collapsenofive"
                    >
                      Matplotlib/Seaborn
                    </button>
                  </h2>
                  <div
                    id="collapsenofive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnofive"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>Importance of every visualization</li>
                        <li>various plots using Matplotlib/Seaborn</li>
                        <li> Introduction to interactive plots</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnothirteen">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenothirteen"
                      aria-expanded="false"
                      aria-controls="collapsenothirteen"
                    >
                      Statistics
                    </button>
                  </h2>
                  <div
                    id="collapsenothirteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnothirteen"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>
                          Introduction to Statistics with Data Science -
                          Concept, Importance, Statistical evidences and action
                          based on the data complexity
                        </li>
                        <li>
                          Descriptive Statistics - Measures of Central Tendency
                          and Measures of Dispersion, Skewness and Kurtosis,
                          Outliers using Box Plot analysis
                        </li>
                        <li>
                          Diagnostic Statistics - Understanding correlation, how
                          to identity and address Multicollinearity
                        </li>
                        <li>
                          Inferential Statistics - Central Limit Theorem,
                          Hypothesis Testing, Understanding P-value, Sampling
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnoseven">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenoseven"
                      aria-expanded="false"
                      aria-controls="collapsenoseven"
                    >
                      Exploratory Data Analysis
                    </button>
                  </h2>
                  <div
                    id="collapsenoseven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnoseven"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>Read the file.</li>
                        <li>
                          Finding the shape / Data type / Descriptive Analytics.
                        </li>
                        <li>
                          Univariate Analysis - Statistical and with various
                          visualizations.
                        </li>
                        <li>
                          Bivariate Analysis - Various visualization techniques.
                        </li>
                        <li>
                          Multivariate Analysis - Cross tabulation and with
                          multiple visualization.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnosix">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenosix"
                      aria-expanded="false"
                      aria-controls="collapsenosix"
                    >
                      Introduction to Machine Learning
                    </button>
                  </h2>
                  <div
                    id="collapsenosix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnosix"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>What are Classification and Regression Models?</li>
                        <li>
                          Understanding the concept of Supervised and
                          Unsupervised Learning
                        </li>
                        <li>
                          How Machine Learning is different from our traditional
                          software development?
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnoeight">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenoeight"
                      aria-expanded="false"
                      aria-controls="collapsenoeight"
                    >
                      Regression
                    </button>
                  </h2>
                  <div
                    id="collapsenoeight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnoeight"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <p>
                        Linear and Multilinear Regression Models, with important
                        Parameters for Model Development
                      </p>
                      <ul>
                        <li>
                          Learn how to solve real life problem using the Linear
                          Regression technique.
                        </li>
                        <li>
                          Initial analysis of data using Univariate, Bivariate
                          and Multivariate analysis before fitting into the
                          Linear regression Model.
                        </li>
                        <li>
                          How does correlation between variables affect
                          Regression Models?
                        </li>
                        <li>
                          {" "}
                          What are the libraries and plots we can utilize to
                          detect and remove outliers in a dataset for a
                          Regression Model?
                        </li>
                        <li>
                          In-depth knowledge of data collection and data
                          preprocessing for Machine Learning Linear Regression
                          problem
                        </li>
                        <li>
                          Understanding the business problems and fit into a
                          Linear Regression Model.
                        </li>
                        <li>
                          Assumptions to be considered for Linear Regression
                          Models.
                        </li>
                        <li>
                          Based on past data, predict future outcomes by using
                          Simplest Machine Learning algorithm.
                        </li>
                        <li>
                          Use measures and metrics for evaluating the good fit
                          for your linear regression model on specific dataset,
                          and ways of improving the model fit.
                        </li>
                        <li>
                          Understand how to interpret the result of Linear
                          Regression model and translate them into actionable
                          insight.
                        </li>
                        <li>
                          {" "}
                          Linear Regression technique of Machine Learning using
                          SciKit Learn and Statsmodel libraries of Python.
                        </li>
                        <li>
                          Importance of Train and Test Data – Sample Dataset /
                          Out of sample Dataset / K-Fold Validation Dataset
                        </li>
                        <li>
                          Learn advanced variations of OLS method of Linear
                          Regression
                        </li>
                        <li>Use Cases hands-on.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnothertin">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenothertin"
                      aria-expanded="false"
                      aria-controls="collapsenothertin"
                    >
                      Clustering (Unsupervised learning)
                    </button>
                  </h2>
                  <div
                    id="collapsenothertin"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnothertin"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>Introduction to Unsupervised Learning</li>
                        <li>
                          Clustering – Business Use Case & Types of Clustering
                        </li>
                        <li>
                          Hierarchical Clustering – Concept, Measures of Cluster
                          Linkage, Metrics for Distance Calculations, Types of
                          Hierarchical Clustering and its features, Advantages
                          and Disadvantages
                        </li>
                        <li>
                          {" "}
                          Centroid based K-Means Clustering – Concept, How
                          K-means works, Challenges, Finding Optimum K value
                          using Elbow Method and Silhouette Score
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnonine">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenonine"
                      aria-expanded="false"
                      aria-controls="collapsenonine"
                    >
                      Classification
                    </button>
                  </h2>
                  <div
                    id="collapsenonine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnonine"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <p>
                        Logistic Regression, Decision Tree, Random Forest with
                        important Parameters for Model Development using SciKit
                        Learn
                      </p>
                      <ul>
                        <li>
                          Understanding the importance of removing correlated /
                          multi-collinearity variables
                        </li>
                        <li>What do you mean by Bias and Variance?</li>
                        <li>
                          Apply Machine Learning Models for various Industries.
                        </li>
                        <li>
                          Develop a fraud detection classifier using Machine
                          Learning Techniques
                        </li>
                        <li>
                          Understand the theory and intuition behind several
                          machine learning algorithms.
                        </li>
                        <li>
                          Perform feature engineering and clean your training
                          and testing data to remove outliers.
                        </li>
                        <li>
                          Understanding the importance of the Confusion Matrix.
                        </li>
                        <li>
                          The different types of model evaluation metrics, such
                          as accuracy, precision, and recall and how to
                          interpret them using Classification Report.
                        </li>
                        <li>
                          Advantages and Disadvantages of each Machine Learning
                          algorithm.
                        </li>
                        <li>
                          Techniques for building and evaluating Machine
                          Learning Models, such as feature selection, feature
                          engineering, and model evaluation techniques.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnoten">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenoten"
                      aria-expanded="false"
                      aria-controls="collapsenoten"
                    >
                      Introduction to Storytelling & How to tell a story?
                    </button>
                  </h2>
                  <div
                    id="collapsenoten"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnoten"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>
                          Fundamentals of Visualizing data - Introduction to
                          Storytelling, The Art of Storytelling and Building a
                          Story
                        </li>
                        <li>
                          Understanding Visuals and Narratives, Guided
                          Storytelling, Understanding Your Audience
                        </li>
                        <li>
                          Identifying a Story, Key Elements in a Story, building
                          a Storyboard, Importance of Visuals, Designing
                          Visualizations, Selecting the right medium for
                          Storytelling.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnoeleven">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenoeleven"
                      aria-expanded="false"
                      aria-controls="collapsenoeleven"
                    >
                      Choosing Visualizations & Design Principles
                    </button>
                  </h2>
                  <div
                    id="collapsenoeleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnoeleven"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>
                          Visualization Best Practices - Data Types, Data
                          Relationships
                        </li>
                        <li>
                          Choosing the right visualization method - Tables vs
                          Charts, Basic Chart Types
                        </li>
                        <li>
                          Chart selection - Right chart for right data - Bar
                          Charts, Line, Scatter and Bubble Charts, Heat maps,
                          Area and Pie Charts, Histograms and word clouds, Geo
                          mapping, Infographics and Dashboards.
                        </li>
                        <li>Hands-on on Power BI with practice session</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-item-1">
                  <h2 className="accordion-header" id="headingnotwelve">
                    <button
                      className="accordion-button ac-btn-1 collapsed font-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenotwelve"
                      aria-expanded="false"
                      aria-controls="collapsenotwelve"
                    >
                      Art of Data Visualization using Power BI
                    </button>
                  </h2>
                  <div
                    id="collapsenotwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnotwelve"
                    data-bs-parent="#accordionexample"
                  >
                    <div className="accordion-body ac-body-1">
                      <ul>
                        <li>
                          Introduction to Power BI, Power BI Data source
                          connection, Introduction to Power Query, Connecting to
                          CSV, Excel, Text Files.
                        </li>
                        <li>
                          {" "}
                          Power Query - Data loading and transformation,
                          Replacing Values, Data and table Transformations using
                          power query function.
                        </li>
                        <li>
                          <p>
                            Exploration of Data- Identifying outliers, handling
                            missing values, Univariate and Bivariate analysis,
                            Building Power View Visualizations :
                          </p>
                          <ul>
                            <li>Tables</li>
                            <li>Bar, Column and Pie Charts</li>
                            <li> Line and Scatter Charts</li>
                          </ul>
                          <p>
                            Formatting Charts - Customizing Colours,
                            backgrounds, axis etc, Creating Dashboard and
                            reports in Power BI, Dashboard filters - Visual
                            level and Create a frame work of story using charts
                            & dashboards, Insights generated to help form a
                            storyline with potential solutions for business
                            statements.
                          </p>
                        </li>
                        <li>Hands-on on Power BI with practice session</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary nav-btn"
                  onClick={handleClick}
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  Register Now
                </button>
                {/* <a target='_blank' download="Syllabus" href={Syllabus} rel="noreferrer"> */}
                <button
                  className="btn btn-primary nav-btn2"
                  onClick={handleDownload}
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  Download Syllabus
                </button>
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="program">
        <div className="container">
          <h3
            className="font-bold text-center om-head tx-color"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            Outcome of this Program
          </h3>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="outcome"
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <h4 className="font-semibold mb-4 tx-color">
                  If you’re from Non-Technical background, you will act as Data
                  Science Consultant
                </h4>
                <p>
                  1. As Formulator for data-based problems: Finding various ways
                  to solve, using Machine Learning Models.
                </p>
                <p>
                  2. As Strategy Developer: Determining probable outcomes that
                  help businesses to develop alternative plans to conduct their
                  new Strategies to meet their business objectives using Data
                  Science Modelling.
                </p>
                <p>
                  3. As Strategy verifier: Verifying or validating the Model
                  outcome for finalizing a business strategy by determining its
                  expected effectiveness. As Consultant, you can monitor how new
                  strategies are working to meet the business objectives and run
                  programs that help business to predict the long-term
                  effectiveness of those strategies.
                </p>
                <p>
                  4. As Model Developer: Contribute for Design and Model
                  building tools that are to be customized to serve a business's
                  (unique) needs.
                </p>
                <p>
                  5. As Trainer: Data Science Consultants can train Model
                  working for their clients' team to improve their data literacy
                  and involve them as they improve business processes.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img src={P1} alt="people" className="science1" />
            </div>
          </div>
          <div className="mt-5">
            <div className="row">
              <div
                className="col-lg-6 order-lg-2"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="outcome">
                  <h4 className="font-semibold mb-4 tx-color">
                    Technical Participants as Data Analyst / Data Scientist
                  </h4>
                  <p>
                    1. As Value Creator: Using Business Analytics and Data
                    Science in making better decisions that lead to
                    organizational success with cost reduction, effort
                    estimation, and cost optimization.
                  </p>
                  <p>
                    2. As Data Analyst: Identify and avoid common mistakes while
                    interpreting datasets, metrics, and visualizations. Present
                    data using various data visualization techniques and tools.
                  </p>
                  <p>
                    3. As Data Scientist: Speaking common language with other
                    Data Scientist teams to uncover actionable recommendations
                    and findings. The following points to be noted, a.
                    Discovering Trends and Patterns, combining various
                    algorithms and modules. b. Performance Comparison of various
                    Machine Models using Confusion Matrix and ROC Curve. c.
                    Optimization - Hyper-Parameter Tuning using GridSearchCV. d.
                    Identifying relevant data sources and appropriate algorithms
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img src={P2} alt="technology" className="science2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3
          className="font-bold text-center  tx-color"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1500"
        >
          Project
        </h3>
        <p
          className="w-50 mx-auto text-center"
          data-aos="fade-up"
          data-aos-delay="120"
          data-aos-duration="1500"
          style={{ color: "#646464" }}
        >
          Complete real-world projects as part of your Data Science & AI
          Certification. We <br></br>will provide one month of support to
          complete a project and obtain the<br></br> Certification from Krutak.
        </p>
      </div>
      <div className="container">
        <div className="pr-row">
          <div className="row">
            {Project.map((item, index) => {
              return (
                <div
                  className="col-lg-4 "
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  key={index}
                >
                  <div className="project-card">
                    <img src={item.img} alt="project" />
                    <p className="font-bold text-center">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 ">
            <h3
              className="font-bold text-center  tx-color"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              Most affordable and Most Advanced with <br></br>real world
              training
            </h3>
            {/* <p
              className="text-center"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
              style={{ color: "#646464" }}
            >
              We are offering a 2-hour trial online session where we discuss the
              course overview and what you will learn from this data science
              course. We will also explain how this course can benefit your
              career growth.
              <span
                className="font-bold tx-color"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                {" "}
                The trial session fee is Rs. 99.
              </span>{" "}
              Once you find it suitable and wish to proceed, you can join the
              program by making a{" "}
              <span
                className="font-bold tx-color"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                one-time payment of Rs. 18,000.
              </span>
            </p> */}
            <p
              className="text-center"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
              style={{ color: "#646464" }}
            >This incredible price on our data science course won't last forever. Plus, if you're not 100% satisfied after 2 weeks, we'll give you your money back. Basically, it's a risk-free chance to launch your data science career and up-skill yourself!</p>
          </div>
        </div>
        <div className="my-5 cards-cnt">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-12 cm-card-md">
              <div
                className="com-card"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div>
                  <img src={U1} alt="" />
                </div>
                <div>
                  <p className="font-bold">Krutak Research</p>
                  <p className="font-bold">
                    <span className="font-bold">&#x20B9;</span>18,000
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="d-flex justify-content-center font-exbold">
                VS
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="row">
                {Compare.map((item, index) => {
                  return (
                    <div
                      className="col-md-3 col-6 "
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                      key={index}
                    >
                      <div className="sm-card">
                        <div>
                          <img src={item.image} alt="" />
                        </div>
                        <div className="d-flex align-items-center flex-column sm-card-cnt">
                          <p className="">{item.name}</p>
                          <p className="font-bold">
                            <span className="font-bold">&#x20B9;</span>
                            {item.amount}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-center">
            <button
              className="btn btn-primary nav-btn"
              onClick={handleClick}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
      <div className="container my-5 pb-4">
        <h3
          className="font-bold mb-5 text-center  tx-color"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1500"
        >
          Why Krutak Learning?
        </h3>
        <div className="row justify-content-center">
          <div className="col-lg-4 mb-3">
            <div
              className="wk-learning"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <h5 className="font-bold mb-3">Network Opportunities</h5>
              <p className="pb-0">
                Based on the business goals, while doing project submission, you
                can exchange ideas with thoughts and create long-term
                relationships with people who can help you to make your next
                career transition.
              </p>
            </div>
          </div>
          {/* <div className="col-lg-4 mb-3">
                        <div
                            className="wk-learning"
                            data-aos="zoom-in"
                            data-aos-delay="100"
                            data-aos-duration="1500"
                        >
                            <h5 className="font-bold mb-3">Secure Your Dream Job</h5>
                            <p className="pb-0">
                                Tata Group, Sopra Steria, Welspun, The Times, HUL, RR Donnelley,
                                AstraZeneca, Societe Generale, Tiger Analytics, IDFC Bank,
                                MassMutual, MiQ, Coforge, LTI, etc.
                            </p>
                        </div>
                    </div> */}
          <div className="col-lg-4 mb-3">
            <div
              className="wk-learning"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <h5 className="font-bold mb-3">Certification</h5>
              <p className="pb-0">
                After successful completion of the course, you will be awarded a
                Certificate of Data Science from Krutak Research Analytics.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5 pb-4">
        <h3
          className="font-bold mb-5 text-center  tx-color"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="1500"
        >
          Participants Profile, Work Experience and Industry
        </h3>
        <div className="row justify-content-center">
          <div className="col-lg-6 mb-3">
            <div
              className="chart-border"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img className="w-100 h-auto" src={Chart1} alt="" />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div
              className="chart-border"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img className="w-100 h-auto" src={Chart2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <h3
              className="font-bold text-center mb-4  tx-color"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              FAQs
            </h3>
            <div
              className="oldacc"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="accordion" id="accordionExample">
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2one">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2one"
                      aria-expanded="false"
                      aria-controls="collapse2one"
                    >
                      What is data science?
                    </button>
                  </h2>
                  <div
                    id="collapse2one"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2one"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Data science is the analysis / study of data to extract
                        meaningful inferences that can benefit business goals.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2two">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2two"
                      aria-expanded="false"
                      aria-controls="collapse2two"
                    >
                      Who is eligible for this data science course?
                    </button>
                  </h2>
                  <div
                    id="collapse2two"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2two"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Anyone who is passionate in obtaining significant
                        insights from data, can benefit. Professionals from any
                        domains/industries like Banking, Automotives, Insurance,
                        Retail, Healthcare, Manufacturing, etc., can enhance
                        their decision-making processes through Data Science. As
                        the demand for data-based insights continues to rise
                        across specific domains/industries, learning Data
                        Science can open gates to many career prospects.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2three">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2three"
                      aria-expanded="false"
                      aria-controls="collapse2three"
                    >
                      Is this the best data science course?
                    </button>
                  </h2>
                  <div
                    id="collapse2three"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2three"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        The data science and machine learning course by Kalyan
                        Ramani is bespoke in nature. The contents of the course
                        are picked up from real world business use cases that
                        will be practiced frequently. Every session is practical
                        based with one-on-one interaction with the trainer.
                        There are separate doubt clearing sessions to help you
                        benefit from this course to the fullest. Unlike the
                        courses offered by popular online course providers, our
                        course focuses to be the most affordable one with
                        maximum learnings and takeaways.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2four">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2four"
                      aria-expanded="false"
                      aria-controls="collapse2four"
                    >
                      What is the Fees Structure?
                    </button>
                  </h2>
                  <div
                    id="collapse2four"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2four"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        The Fee structure is Rs.18,000 for a 50 hour course. All
                        sessions will be practical based for interactive and
                        hands on learning experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2five">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2five"
                      aria-expanded="false"
                      aria-controls="collapse2five"
                    >
                      Is there a trial before purchasing the course?
                    </button>
                  </h2>
                  <div
                    id="collapse2five"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2five"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        You can attend the introduction webinar to know about
                        the course and how you can benefit from it. Register
                        here.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2six">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2six"
                      aria-expanded="false"
                      aria-controls="collapse2six"
                    >
                      Does data science need coding?
                    </button>
                  </h2>
                  <div
                    id="collapse2six"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2six"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        No, there is no prerequisite for coding to learn data
                        science and machine learning. Even if you are from a
                        non-technical professional background, you can learn
                        data science. The basics of python will be covered as
                        part of fundamentals to all enrollees.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2seven">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2seven"
                      aria-expanded="false"
                      aria-controls="collapse2seven"
                    >
                      Any prior experience needed?
                    </button>
                  </h2>
                  <div
                    id="collapse2seven"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2seven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>No, prior experience is not required.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2eight">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2eight"
                      aria-expanded="false"
                      aria-controls="collapse2eight"
                    >
                      What is the EMI payment process?
                    </button>
                  </h2>
                  <div
                    id="collapse2eight"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2eight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Our fee structure is most affordable. We currently do
                        not offer EMI payment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2nine">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2nine"
                      aria-expanded="false"
                      aria-controls="collapse2nine"
                    >
                      How will my queries be answered?
                    </button>
                  </h2>
                  <div
                    id="collapse2nine"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2nine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Exclusive doubt clearing sessions will be held to answer
                        all your queries regarding the subject.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item ac-it-2">
                  <h2 className="accordion-header" id="heading2ten">
                    <button
                      className="accordion-button ac-btn-2 collapsed font-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2ten"
                      aria-expanded="false"
                      aria-controls="collapse2ten"
                    >
                      Do I get a certificate after compilation for the course?
                    </button>
                  </h2>
                  <div
                    id="collapse2ten"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2ten"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, you will get a completion certificate after the
                        successful completion of the course. This certificate
                        may be used for professional purposes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary nav-btn"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
                onClick={handleClick}
              >
                Register Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="down">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mt-5 pt-4">
              <img src={logo} className="logo" alt="logo" />
              <p className="para mt-4">
                Krutak Research Analytics Pvt Ltd – Where people, ideas, and
                innovation meet! We provide Data Science solutions for your
                complex business scenarios.
              </p>
              <div
                className="d-flex w-50 mb-5 pb-5 social-sml justify-content-between mt-4"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="sticker me-2">
                  <a
                    href=" https://www.facebook.com/KrutakResearchAnalytics"
                    target="_blank"
                  >
                    <i
                      class="fa-brands fa-facebook-f number"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
                {/* <div className='sticker me-2'>
                                    <a href='https://www.telegram.com/' target='_blank'>
                                        <i class="fa-brands fa-twitter number"></i>
                                    </a>
                                </div> */}
                <div className="sticker me-2">
                  <a
                    href=" https://www.linkedin.com/company/krutak-research-analytics/"
                    target="_blank"
                  >
                    <i class="fa-brands fa-linkedin-in number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a
                    href=" https://www.youtube.com/@KalyanaramanRamani-ow6bk"
                    target="_blank"
                  >
                    <i class="fa-brands fa-youtube number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a
                    href=" https://www.instagram.com/krutakresearchanalytics/"
                    target="_blank"
                  >
                    <i class="fa-brands fa-instagram number"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className=" goal col-lg-4 mt-5 pt-5 ms-2">
                  <h5>Quick Links</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li className="nav-item mb-2">
                      <a
                        href="#/Termsandconditions"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Privacypolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Refundpolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Cancellation and Refund Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/ShippingPolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Shipping Policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-3 mt-5 pt-5 ms-2">
                  <h5>Contact Us</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li class="">
                      <div className="map mt-2">
                        <i
                          class="fa-regular fa-envelope me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <div>
                          <p className="para1 mb-1">Email :</p>
                          <p className="para2">
                            <a
                              href="mailTo:connect@krutakresearchanalytics.com"
                              target="_blank"
                              className=" para2 five "
                              style={{ color: "#ffffff" }}
                            >
                              connect@krutakresearchanalytics.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="map mt-2">
                        <i
                          class="fa-solid fa-location-dot me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <div>
                          <p className="para1 mb-1">Address :</p>
                          <p className="para2 ">
                            Tower 42, 3rd floor, Flat No.2, <br /> DLF
                            Gardencity Thalambur, <br /> Chennai - 600 130
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <p className="text-center">
              © Copyright 2024. All Rights Reserved By Krutak Research
              Analytics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
