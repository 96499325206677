import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Logoimg from "../Assets/Images/logo.webp";
import logo from "../Assets/Images/logo.webp";
import axios from "axios";

const TestPayment = () => {
  const [orderId, setOrderId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [signature, setSignature] = useState('');

  const createOrder = async () => {

    try {
      let inputValue = {
        amount: 100,
        currency: 'INR',
        name: 'Test Payment',
        description: 'This is a test payment',
      };
      let form_data = new FormData();
      for (let key in inputValue) {
        form_data.append(key, inputValue[key]);
      }
      let data = await axios
        .post(
          "https://onlinedatasciencecourse.krutakresearchanalytics.com/api/api.php",
          form_data
        )
      console.log("Data", data.data.order_id)
      setOrderId(data.data.order_id);
      handlePayment()
    } catch (error) {
      console.error("Error writing to Google Sheets:", error);
    }
  }
  const validatePayment = async (signature,paymentId) => {

    try {
      let inputValue = {
        razorpay_payment_id: paymentId,
        razorpay_signature: signature
      };
      let form_data = new FormData();
      for (let key in inputValue) {
        form_data.append(key, inputValue[key]);
      }
      let data = await axios
        .post(
          "https://onlinedatasciencecourse.krutakresearchanalytics.com/api/api.php",
          form_data
        )
      console.log("Data", data)
    } catch (error) {
      console.error("Error writing to Google Sheets:", error);
    }
  }
  const handlePayment = async () => {
    const options = {
      key: 'rzp_test_4EjU0TZdE3NhQs',
      amount: 1000, // Amount in paise
      currency: 'INR',
      name: 'Test Payment',
      description: 'This is a test payment',
      order_id: orderId,
      handler: function (response) {
        console.log("response", response)
        // setPaymentId(response.razorpay_payment_id);
        // setSignature(response.razorpay_signature);
        if(response.razorpay_signature){
          validatePayment(response.razorpay_signature,response.razorpay_payment_id)
        }
        // validatePayment()
        // Send paymentId and signature to your backend for verification
        // You can use fetch or axios to make a POST request
      },
      prefill: {
        name: 'Test User',
        email: 'test@example.com',
        contact: '9999999999',
      },
      notes: {
        address: 'Test Address',
      },
      theme: {
        color: '#F37254',
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <div>
      <button onClick={createOrder}>Create Order</button>
      <p>Order ID: {orderId}</p>
      <p>Payment ID: {paymentId}</p>
      <p>Signature: {signature}</p>
    </div>
  );
};

export default TestPayment
