import React from 'react'
import Policycontent from '../Components/Policycontent'

const Privacypolicy = () => {
  return (
    <div>
        <Policycontent/>
    </div>
  )
}

export default Privacypolicy