import React from 'react'
import Termsandconditionscontent from '../Components/Termsandconditionscontent'

const Termsandconditions = () => {
  return (
    <div>
        <Termsandconditionscontent/>
    </div>
  )
}

export default Termsandconditions