import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRef } from "react";
import Logoimg from "../Assets/Images/logo.webp";
import logo from "../Assets/Images/logo.webp";

const Refundpolicycontent = () => {
  const inputRef = useRef(null);
  const [closeNav, setCloseNav] = useState(false);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="bg-light-nav-top">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <div className="container">
            <NavLink className="navbar-brand d-flex align-items-end" to="/">
              <img className="logo-img" src={Logoimg} alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                closeNav === true ? "d-none" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/about"
                  >
                    About Krutak
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/team"
                  >
                    Leadership Team
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/service"
                  >
                    Services
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/product">Products</NavLink>
                        </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/position"
                  >
                    Careers
                  </NavLink>
                </li>
                {/*  <li className="nav-item">
                                   <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/course">Webinar</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                <NavLink to="/?id=info">
                    <button
                      type="button"
                      className="btn btn-primary nav-btn"
                      // onClick={() => {
                      //     handleClick();
                      //     setCloseNav(true);
                      //     setTimeout(() => {
                      //         setCloseNav(false)
                      //     }, [1000])
                      // }}
                    >
                      Get in Touch
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="note">
        <div className="container mt-5 pt-5 mb-5 pb-2">
          <p className="h3 mt-5 pt-5">Cancellation and Refund Policy</p>
          <p className="para3">Last Updated: February 22, 2024</p>
          <div className="content1">
            <p className="h3">Cancellation Policy:</p>
            <p className="para6">
              Customers can request the cancellation of services offered by
              Krutak Research Analytics by contacting us through email at
              connect@krutakresearchanalytics.com. Each cancellation request
              will be handled individually.
            </p>
          </div>
          <div className="content2">
            <p className="h3">Refund Policy:</p>
            <p className="para7">
              Krutak Research Analytics is committed to providing our customers
              with a satisfactory experience. Refunds may be issued based on the
              following criteria:{" "}
            </p>
            <p className="h5">Cancellation Before Service Initiation:</p>
            <ul>
              <li className="three">
                If a customer requests the cancellation of a service before it
                has been initiated, a refund may be provided. The amount
                refunded will be determined on a case-by-case basis.
              </li>
            </ul>
            <p className="h5">Service Initiated:</p>
            <ul>
              <li className="three">
                If a service has already been initiated, the possibility of a
                refund will depend on the extent of the service completed and
                the circumstances surrounding the cancellation request. Refunds,
                if applicable, will be determined on an individual basis.
              </li>
            </ul>
            <p className="h5">Quality of Service:</p>
            <ul>
              <li className="three">
                If a customer is dissatisfied with the quality of the service
                provided, they can contact us at
                connect@krutakresearchanalytics.com to discuss their concerns.
                Refunds, if any, will be assessed based on the merit of the
                dissatisfaction and the specific circumstances.
              </li>
            </ul>
          </div>
          <div className="content5">
            <p className="h3">5. Information Sharing with Third Parties:</p>
            <p className="para10">
              We do not sell, trade, or otherwise transfer your personal
              information to third parties without your consent, except for the
              following purposes:
            </p>
            <ul>
              <li className="three">
                Service Providers: We may share information with third-party
                service providers who assist us in operating our website and
                providing services.
              </li>
              <li className="three">
                Legal Compliance: We may disclose information if required by law
                or to protect our rights.
              </li>
            </ul>
          </div>
          <div className="content6">
            <p className="h3">Handling of Requests:</p>
            <p className="para11">
              All requests for cancellations and refunds will be handled
              individually, and decisions will be made at the discretion of
              Krutak Research Analytics. Customers are encouraged to provide
              detailed information about their request to facilitate a fair and
              prompt assessment.
            </p>
          </div>
          <p className="h3">Contact Information:</p>
          <p className="para13">
            For cancellation and refund requests or inquiries, please contact us
            at connect@krutakresearchanalytics.com.
          </p>
        </div>
      </div>
      <div className="down">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mt-5 pt-4">
              <img src={logo} className="logo" alt="logo" />
              <p className="para mt-4">
                Krutak Research Analytics – Where people, ideas, and innovation
                meet! We provide Data Science solutions for your complex
                business scenarios.
              </p>
              <div
                className="d-flex w-50 mb-5 pb-5 social-sml justify-content-between mt-4"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="sticker me-2">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i
                      class="fa-brands fa-facebook-f number"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.telegram.com/" target="_blank">
                    <i class="fa-brands fa-twitter number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="fa-brands fa-linkedin-in number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.youtube.com/" target="_blank">
                    <i class="fa-brands fa-youtube number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="fa-brands fa-instagram number"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className=" goal col-lg-4 mt-5 pt-5 ms-2">
                  <h5>Quick Links</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li className="nav-item mb-2">
                      <a
                        href="#/Termsandconditions"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Privacypolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Refundpolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Cancellation and Refund Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2"><a href="#/ShippingPolicy" className="nav-link p-0 text-body-secondary">Shipping Policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-3 mt-5 pt-5 ms-2">
                  <h5>Contact Us</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li class="">
                      <a href="">
                        <i
                          class="fa-regular fa-envelope me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <span style={{ color: "white" }}>Email:</span> <br />
                        <a
                          href="https://www.gmail.com/"
                          target="_blank"
                          className=" five ms-4"
                          style={{ color: "#ffffff" }}
                        >
                          connect@krutakresearchanalytics.com
                        </a>
                      </a>
                    </li>
                    <li>
                      <div className="map mt-3">
                        <i
                          class="fa-solid fa-location-dot me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <p className="para1">Address:</p>
                      </div>
                      <p className="para2 ms-3">DLF Gardencity, Chennai.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <p className="text-center">
              © Copyright 2024. All Rights Reserved By Krutak Research
              Analytics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refundpolicycontent;
