import React from 'react'
import Refundpolicycontent from '../Components/Refundpolicycontent'

const Refundpolicy = () => {
  return (
    <div>
        <Refundpolicycontent/>
    </div>
  )
}

export default Refundpolicy