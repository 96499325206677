import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";
import { useRef } from "react";
import Logoimg from "../Assets/Images/logo.webp";
import logo from "../Assets/Images/logo.webp";


const Termsandconditionscontent = () => {
    const inputRef = useRef(null);
    const [closeNav, setCloseNav] = useState(false);
    
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    useEffect(() => {
       window.scrollTo(0, 0);
    });
    
    return (
        <div>
            <div className="bg-light-nav-top">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                    <div className="container">
                        <NavLink className="navbar-brand d-flex align-items-end" to="/">
                            <img className="logo-img" src={Logoimg} alt="" />
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${closeNav === true ? "d-none" : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/about">About Krutak</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/team">Leadership Team</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/service">Services</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/product">Products</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/position">Careers</NavLink>
                                </li>
                               {/*  <li className="nav-item">
                                   <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/course">Webinar</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/contact">Contact Us</NavLink>
                                </li>
                                <li className="nav-item">
                                <NavLink to="/?id=info">
                                    <button
                                        type="button"
                                        className="btn btn-primary nav-btn"
                                        // onClick={() => {
                                        //     handleClick();
                                        //     setCloseNav(true);
                                        //     setTimeout(() => {
                                        //         setCloseNav(false)
                                        //     }, [1000])
                                        // }}
                                    >
                                        Get in Touch
                                    </button>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className='note'>
                <div className='container mt-5 pt-5 mb-5 pb-2'>
                    <p className='h3 mt-5 pt-5'>Terms and Conditions</p>
                    <p className='para3'>Last Updated: February 22, 2024</p>
                    <p className='para4'>Welcome to Krutak Research Analytics! These Terms and Conditions outline the rules and regulations for the use of our website.</p>
                    <p className='para5'>By accessing this website, we assume you accept these Terms and Conditions in full. Do not continue to use Krutak Research Analytics' website if you do not accept all of the terms and conditions stated on this page.</p>
                    <div className='content1'>
                      <p className='h3'>1. License to Use the Website:</p>
                      <p className='para6'>Unless otherwise stated, Krutak Research Analytics and/or its licensors own the intellectual property rights for all material on this website. You may view and/or print pages from {"https://www.krutakresearchanalytics.com"} for your own personal use subject to restrictions set in these terms and conditions.</p>
                    </div>
                    <div className='content2'>
                      <p className='h3'>2. Acceptable Use:</p>
                      <p className='para7'>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website, or in any way which is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
</p>
                    </div>
                    <div className='content3'>
                      <p className='h3'>3. User-Generated Content:</p>
                      <p className='para8'>Certain areas of the website allow users to post and exchange opinions, information, material, and data. However, Krutak Research Analytics reserves the right to monitor, edit, or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene, or otherwise objectionable or violates any party's intellectual property or these Terms and Conditions.</p>
                    </div>
                    <div className='content4'>
                      <p className='h3'>4. Privacy:</p>
                      <p className='para9'>Your use of our website is also governed by our Privacy Policy. Please review our Privacy Policy, which details how we collect, use, and protect your personal information.</p>
                    </div>
                    <div className='content5'>
                      <p className='h3'>5. Limitations:</p>
                      <p className='para10'>In no event shall Krutak Research Analytics or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Krutak Research Analytics' website, even if Krutak Research Analytics or a Krutak Research Analytics authorized representative has been notified orally or in writing of the possibility of such damage.</p>
                    </div>
                    <div className='content6'>
                      <p className='h3'>6. Governing Law:</p>
                      <p className='para11'>These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction] and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                    </div>
                    <div className='content7'>
                      <p className='h3'>7. Changes to Terms:</p>
                      <p className='para12'>Krutak Research Analytics reserves the right to revise these terms and conditions at any time. By using this website, you agree to be bound by the current version of these terms and conditions.</p>
                    </div>
                    <p className='h3'>Contact Information:</p>
                    <p className='para13'>If you have any questions or concerns about these Terms and Conditions, please contact us at connect@krutakresearchanalytics.com.</p>
                </div>
            </div>
            <div className="down">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mt-5 pt-4">
                            <img src={logo} className="logo" alt="logo" />
                            <p className="para mt-4">Krutak Research Analytics – Where people, ideas, and innovation meet! We provide Data Science solutions for your complex business scenarios.</p>
                            <div className='d-flex w-50 mb-5 pb-5 social-sml justify-content-between mt-4' data-aos="zoom-in" data-aos-delay="300">
                                <div className='sticker me-2'>
                                <a href='https://www.facebook.com/' target='_blank'>
                                    <i class="fa-brands fa-facebook-f number" style={{ color: "white" }}></i>
                                    </a>
                                </div>
                                <div className='sticker me-2'>
                                <a href='https://www.telegram.com/' target='_blank'>
                                    <i class="fa-brands fa-twitter number"></i>
                                    </a>
                                </div>
                                <div className='sticker me-2'>
                                <a href='https://www.linkedin.com/' target='_blank'>
                                    <i class="fa-brands fa-linkedin-in number"></i>
                                    </a>
                                </div>
                                <div className='sticker me-2'>
                                <a href='https://www.youtube.com/' target='_blank'>
                                    <i class="fa-brands fa-youtube number"></i>
                                    </a>
                                </div>
                                <div className='sticker me-2'>
                                <a href='https://www.instagram.com/' target='_blank'>
                                    <i class="fa-brands fa-instagram number"></i>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className=' goal col-lg-4 mt-5 pt-5 ms-2'>
                                    <h5>
                                        Quick Links
                                    </h5>
                                    <hr class="dropdown-divider" />
                                    <ul className="nav flex-column mt-3">
                                        <li className="nav-item mb-2"><a href="#/Termsandconditions" className="nav-link p-0 text-body-secondary">Terms and Conditions</a></li>
                                        <li className="nav-item mb-2"><a href="#/Privacypolicy" className="nav-link p-0 text-body-secondary">Privacy Policy</a></li>
                                        <li className="nav-item mb-2"><a href="#/Refundpolicy" className="nav-link p-0 text-body-secondary">Cancellation and Refund Policy</a></li>
                                        <li className="nav-item mb-2"><a href="#/ShippingPolicy" className="nav-link p-0 text-body-secondary">Shipping Policy</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2">

                                </div>
                                <div className='col-lg-3 mt-5 pt-5 ms-2'>
                                    <h5>
                                        Contact Us
                                    </h5>
                                    <hr class="dropdown-divider" />
                                    <ul className="nav flex-column mt-3">
                                        <li class="">
                                            <a href="#">
                                                <i class="fa-regular fa-envelope me-2" style={{ color: "#ffffff" }}></i>
                                                <span style={{color: "white"}}>Email:</span> <br />
                                                <a href='https://www.gmail.com/' target='_blank' className=" five ms-4" style={{color: "#ffffff"}}>
                                                    connect@krutakresearchanalytics.com
                                                </a>
                                            </a>
                                        </li>
                                        <li>
                                            <div className="map mt-3">
                                        <i class="fa-solid fa-location-dot me-2" style={{color: "#ffffff"}}></i>
                                            <p className="para1">Address:</p>
                                            </div>
                                            <p className="para2 ms-3">DLF Gardencity, Chennai.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="footer">
                    <div className="container">
                        <p className="text-center">
                            © Copyright 2024. All Rights Reserved By Krutak Research Analytics.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Termsandconditionscontent