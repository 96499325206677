import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Logoimg from "../Assets/Images/logo.webp";
import logo from "../Assets/Images/logo.webp";

const Policycontent = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [closeNav, setCloseNav] = useState(false);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="bg-light-nav-top">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <div className="container">
            <NavLink className="navbar-brand d-flex align-items-end" to="/">
              <img className="logo-img" src={Logoimg} alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                closeNav === true ? "d-none" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/about"
                  >
                    About Krutak
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/team"
                  >
                    Leadership Team
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/service"
                  >
                    Services
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/product">Products</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/position"
                  >
                    Careers
                  </NavLink>
                </li>

                {/*  <li className="nav-item">
                                   <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/course">Webinar</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/?id=info">
                    <button
                      type="button"
                      className="btn btn-primary nav-btn"
                    //   onClick={() => {
                    //     handleClick();
                    //     setCloseNav(true);
                    //     setTimeout(() => {
                    //       setCloseNav(false);
                    //     }, [1000]);
                    //   }}
                    >
                      Get in Touch
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="note">
        <div className="container mt-5 pt-5 mb-5 pb-2">
          <p className="h3 mt-5 pt-5">Privacy Policy</p>
          <p className="para3">Last Updated: February 22, 2024</p>
          <p className="para4">
            Krutak Research Analytics is committed to safeguarding the privacy
            and security of your personal information. This Privacy Policy
            outlines the types of personal information we collect, how it is
            collected, how it is used, and the measures we take to keep it
            secure.
          </p>

          <div className="content1">
            <p className="h3">1. Personal Information Collected:</p>
            <p className="para6">
              We may collect the following types of personal information when
              you visit or use our website:
            </p>
            <ul>
              <li className="three">
                Contact Information: Such as your name, email address, and phone
                number.
              </li>
              <li className="three">
                Demographic Information: Such as your age, gender, and location.
              </li>
              <li className="three">
                Usage Information: Such as your IP address, browser type, and
                device information.
              </li>
            </ul>
          </div>
          <div className="content2">
            <p className="h3">2. How Information is Collected:</p>
            <p className="para7">
              We collect personal information through various methods,
              including:{" "}
            </p>
            <ul>
              <li className="three">
                Directly from You: When you provide information through forms on
                our website.
              </li>
              <li className="three">
                Automatically: Through cookies and similar technologies when you
                use our website.
              </li>
              <li className="three">
                Analytics: To analyze website usage and improve our services.
              </li>
            </ul>
          </div>
          <div className="content3">
            <p className="h3">3. How Collected Information is Used:</p>
            <p className="para8">
              We use the collected information for the following purposes:
            </p>
            <ul>
              <li className="three">
                Communication: To respond to your inquiries and provide
                information about our products or services.
              </li>
              <li className="three">
                Personalization: To tailor our content and services to your
                preferences.
              </li>
              <li className="three">
                Third Parties: We may receive information from third-party
                services, such as social media platforms.
              </li>
            </ul>
          </div>
          <div className="content4">
            <p className="h3">4. Information Security:</p>
            <p className="para9">
              We take appropriate measures to safeguard your personal
              information, including:
            </p>
            <ul>
              <li className="three">
                Secure Storage: Storing your information in a secure
                environment.
              </li>
              <li className="three">
                Access Control: Limiting access to your information to
                authorized personnel.
              </li>
              <li className="three">
                Encryption: Using encryption protocols to protect data during
                transmission.
              </li>
            </ul>
          </div>
          <div className="content5">
            <p className="h3">5. Information Sharing with Third Parties:</p>
            <p className="para10">
              We do not sell, trade, or otherwise transfer your personal
              information to third parties without your consent, except for the
              following purposes:
            </p>
            <ul>
              <li className="three">
                Service Providers: We may share information with third-party
                service providers who assist us in operating our website and
                providing services.
              </li>
              <li className="three">
                Legal Compliance: We may disclose information if required by law
                or to protect our rights.
              </li>
            </ul>
          </div>
          <div className="content6">
            <p className="h3">6. Changes to the Privacy Policy:</p>
            <p className="para11">
              We reserve the right to update or modify this Privacy Policy at
              any time. Changes will be effective immediately upon posting on
              the website.
            </p>
          </div>
          <p className="h3">Contact Information:</p>
          <p className="para13">
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at
            connect@krutakresearchanalytics.com.
          </p>
        </div>
      </div>
      <div className="down">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mt-5 pt-4">
              <img src={logo} className="logo" alt="logo" />
              <p className="para mt-4">
                Krutak Research Analytics – Where people, ideas, and innovation
                meet! We provide Data Science solutions for your complex
                business scenarios.
              </p>
              <div
                className="d-flex w-50 mb-5 pb-5 social-sml justify-content-between mt-4"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="sticker me-2">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i
                      class="fa-brands fa-facebook-f number"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.telegram.com/" target="_blank">
                    <i class="fa-brands fa-twitter number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="fa-brands fa-linkedin-in number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.youtube.com/" target="_blank">
                    <i class="fa-brands fa-youtube number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="fa-brands fa-instagram number"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className=" goal col-lg-4 mt-5 pt-5 ms-2">
                  <h5>Quick Links</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li className="nav-item mb-2">
                      <a
                        href="#/Termsandconditions"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Privacypolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Refundpolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Cancellation and Refund Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2"><a href="#/ShippingPolicy" className="nav-link p-0 text-body-secondary">Shipping Policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-3 mt-5 pt-5 ms-2">
                  <h5>Contact Us</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li class="">
                      <a href="">
                        <i
                          class="fa-regular fa-envelope me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <span style={{ color: "white" }}>Email:</span> <br />
                        <a
                          href="https://www.gmail.com/"
                          target="_blank"
                          className=" five ms-4"
                          style={{ color: "#ffffff" }}
                        >
                          connect@krutakresearchanalytics.com
                        </a>
                      </a>
                    </li>
                    <li>
                      <div className="map mt-3">
                        <i
                          class="fa-solid fa-location-dot me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <p className="para1">Address:</p>
                      </div>
                      <p className="para2 ms-3">DLF Gardencity, Chennai.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <p className="text-center">
              © Copyright 2024. All Rights Reserved By Krutak Research
              Analytics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policycontent;
