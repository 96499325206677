import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import Termsandconditions from './Pages/Termsandconditions'
import Privacypolicy from './Pages/Privacypolicy'
import Refundpolicy from './Pages/Refundpolicy'
import ShippingPolicy from './Components/ShippingPolicy'
import TestPayment from './Components/TestPayment'

const Main = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/Termsandconditions' element={<Termsandconditions />} />
                <Route path='/Privacypolicy' element={<Privacypolicy />} />
                <Route path='/Refundpolicy' element={<Refundpolicy />} />
                <Route path='/ShippingPolicy' element={<ShippingPolicy />} />
                <Route path='/TestPayment' element={<TestPayment />} />
                
            </Routes>
        </HashRouter>
    )
}

export default Main