import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRef } from "react";
import Logoimg from "../Assets/Images/logo.webp";
import logo from "../Assets/Images/logo.webp";

const ShippingPolicy = () => {
  const inputRef = useRef(null);
  const [closeNav, setCloseNav] = useState(false);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="bg-light-nav-top">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <div className="container">
            <NavLink className="navbar-brand d-flex align-items-end" to="/">
              <img className="logo-img" src={Logoimg} alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                closeNav === true ? "d-none" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/about"
                  >
                    About Krutak
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/team"
                  >
                    Leadership Team
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/service"
                  >
                    Services
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/product">Products</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/position"
                  >
                    Careers
                  </NavLink>
                </li>
                {/*  <li className="nav-item">
                                   <NavLink className="nav-link" aria-current="page" to="https://krutakresearchanalytics.com/course">Webinar</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="https://krutakresearchanalytics.com/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/?id=info">
                    <button
                      type="button"
                      className="btn btn-primary nav-btn"
                      // onClick={() => {
                      //     handleClick();
                      //     setCloseNav(true);
                      //     setTimeout(() => {
                      //         setCloseNav(false)
                      //     }, [1000])
                      // }}
                    >
                      Get in Touch
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="">
        <div className="container mt-5 pt-5  pb-2">
          <div className="row pt-5 mt-5">
            <div className="col-lg-4 col-md-4">
              <p className="h5 d-lg-block d-md-block d-none">
                Shipping and Delivery Policy{" "}
                <i class="fa-solid fa-chevron-right"></i>
              </p>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="mb-height">
                <h2 className="font-bold mb-5 tx-color after-line">
                  Shipping & Delivery Policyy
                </h2>
                <p className="para4">Last updated on Mar 4th 2024</p>
                <p className="font-bold">
                  Shipping is not applicable for business.
                </p>
              </div>
              <p className="para4 text-sm fst-italic">Disclaimer: The above content is created at KRUTAK RESEARCH ANALYTICS PRIVATE LIMITED's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="down">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mt-5 pt-4">
              <img src={logo} className="logo" alt="logo" />
              <p className="para mt-4">
                Krutak Research Analytics – Where people, ideas, and innovation
                meet! We provide Data Science solutions for your complex
                business scenarios.
              </p>
              <div
                className="d-flex w-50 mb-5 pb-5 social-sml justify-content-between mt-4"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="sticker me-2">
                  <a href="https://www.facebook.com/" target="_blank">
                    <i
                      class="fa-brands fa-facebook-f number"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.telegram.com/" target="_blank">
                    <i class="fa-brands fa-twitter number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="fa-brands fa-linkedin-in number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.youtube.com/" target="_blank">
                    <i class="fa-brands fa-youtube number"></i>
                  </a>
                </div>
                <div className="sticker me-2">
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="fa-brands fa-instagram number"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className=" goal col-lg-4 mt-5 pt-5 ms-2">
                  <h5>Quick Links</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li className="nav-item mb-2">
                      <a
                        href="#/Termsandconditions"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Privacypolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/Refundpolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Cancellation and Refund Policy
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a
                        href="#/ShippingPolicy"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Shipping Policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-3 mt-5 pt-5 ms-2">
                  <h5>Contact Us</h5>
                  <hr class="dropdown-divider" />
                  <ul className="nav flex-column mt-3">
                    <li class="">
                      <a href="#">
                        <i
                          class="fa-regular fa-envelope me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <span style={{ color: "white" }}>Email:</span> <br />
                        <a
                          href="https://www.gmail.com/"
                          target="_blank"
                          className=" five ms-4"
                          style={{ color: "#ffffff" }}
                        >
                          connect@krutakresearchanalytics.com
                        </a>
                      </a>
                    </li>
                    <li>
                      <div className="map mt-3">
                        <i
                          class="fa-solid fa-location-dot me-2"
                          style={{ color: "#ffffff" }}
                        ></i>
                        <p className="para1">Address:</p>
                      </div>
                      <p className="para2 ms-3">DLF Gardencity, Chennai.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <p className="text-center">
              © Copyright 2024. All Rights Reserved By Krutak Research
              Analytics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
